/* Home VotingPresentation animation */
.animate .mouse {
  bottom: 10%;
  left: 0%;
  animation: move-mouse 9s infinite;
}

@keyframes move-mouse {
  0% {
    left: 0%;
    bottom: 0%;
    opacity: 1;
  }
  27% {
    left: 90%;
    bottom: 40%;
    transform: scale(1);
    opacity: 1;
  }
  30% {
    left: 90%;
    bottom: 40%;
    transform: scale(1.1);
    opacity: 1;
  }
  33.33% {
    left: 90%;
    bottom: 40%;
    transform: scale(1);
    opacity: 1;
  }
  50% {
    left: 90%;
    bottom: 40%;
    opacity: 0;
  }
  100% {
    left: 0%;
    bottom: 0%;
    opacity: 0;
  }
}

@keyframes move-game-lg {
  0%,
  33.33% {
    right: 0%;
    transform: scale(1);
  }
  50%,
  80% {
    right: calc(50% - 12.5%);
    transform: scale(1.2);
  }
  100% {
    right: 0%;
    transform: scale(1);
  }
}

@keyframes move-game-md {
  0%,
  33.33% {
    right: 0%;
    transform: scale(1);
  }
  50%,
  80% {
    right: calc(50% - 16.666%);
    transform: scale(1.2);
  }
  100% {
    right: 0%;
    transform: scale(1);
  }
}

@keyframes move-game-sm {
  0%,
  33.33% {
    right: 0%;
    transform: scale(1);
  }
  50%,
  80% {
    right: calc(50% - 22.5%);
    transform: scale(1.2);
  }
  100% {
    right: 0%;
    transform: scale(1);
  }
}

@keyframes hide-others-games {
  0%,
  33.33% {
    opacity: 1;
  }
  50%,
  80% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes hide-progress-bar {
  0%,
  33.33% {
    opacity: 0;
  }
  60%,
  70% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@media (min-width: 1024px) {
  .animate .game-four {
    animation: move-game-lg 9s infinite;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .animate .game-four {
    animation: move-game-md 9s infinite;
  }
}

@media (max-width: 767px) {
  .animate .game-four {
    animation: move-game-sm 9s infinite;
  }
}

.animate .game-one,
.animate .game-two,
.animate .game-three {
  animation: hide-others-games 9s infinite;
}

.animate .progress-bar {
  animation: hide-progress-bar 9s infinite;
}
