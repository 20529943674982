@tailwind base;
@tailwind components;
@tailwind utilities;

h1,
h2,
h3,
h4,
h5,
h6 {
  @apply font-inter;
}

body {
  @apply bg-white-2;
}

main {
  min-height: calc(100vh - 408px); /* 408px = footer (408px) */
}

/* ScrollBar */
::-webkit-scrollbar {
  width: 12px;
}

::-webkit-scrollbar-track {
  @apply bg-white-6;
}

::-webkit-scrollbar-thumb {
  @apply bg-black-5 border border-gray-300 rounded-full;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

::-webkit-scrollbar-thumb:hover {
  @apply bg-black-3;
  transition: background-color 0.2s;
}

@media (min-width: 768px) {
  .left-panel {
    clip-path: polygon(0 0, 100% 0, 90% 100%, 0 100%);
  }

  .right-panel {
    clip-path: polygon(10% 0, 100% 0, 100% 100%, 0 100%);
  }

  .voting .left-panel {
    transform: translateX(55%);
  }

  .voting .right-panel {
    transform: translateX(-55%);
  }
  .zoom-on-hover:hover img {
    transform: scale(1.1);
    transition: transform 0.3s ease-in-out;
  }
}

@media (min-width: 1210px) {
  .voting .left-panel {
    transform: translateX(65%);
  }

  .voting .right-panel {
    transform: translateX(-65%);
  }
}

.swiper-pagination-bullet {
  @apply bg-black-1 !important;
}

.swiper-pagination-bullet-active {
  @apply bg-accent-10 !important;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.crown-animation {
  animation: fadeInUp 0.5s ease-out;
}

ul.custom-bullet {
  list-style-type: disc;
  padding-left: 30px;
}

textarea {
  resize: vertical;
  min-height: 48px;
  max-height: 150px;
}

.swiper-wrapper {
  transition-timing-function: linear !important;
}

.mouse {
  clip-path: polygon(22% 9%, 95% 61%, 46% 71%, 23% 90%);
}
